1<template>
    <div class="box">
        <div class="body">
            <div class="info-title bdr">
                <a-button type="link" icon="left" @click="$router.go(-1)" />{{ info.name }}
            </div>
            <div class="body-content">
                <a-row :gutter="20">
                    <!-- :lg="16" :xl="16" :md="24" -->
                    <a-col :span="16">
                        <mytable v-if="+documentType == 1" :from="from" :info.sync="info" />
                        <fastTable v-else-if="+documentType == 2" :formObj.sync="billInfo" />
                        <billTable v-else-if="+documentType == 3" :formObj.sync="billInfo" />
                    </a-col>
                    <a-col :span="8">
                        <div class="process">
                            <processMess
                                :templateName="templateName"
                                :approvalStatus="approvalStatus"
                                :recipientStatus="recipientStatus"
                                class="bdr"
                                :from="from"
                                :todoType="$route.query.todo"
                                :documentNodeList="documentNodeList"
                                :documentType="documentType"
                                :nooseStatus="nooseStatus"
                            />
                        </div>
                    </a-col>
                </a-row>
            </div>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line camelcase
import { documentBillDetail } from '@/api/modular/fileStream/documentManage'
import mytable from '@/views/fileStream/documentApproval/documentManage/table.vue'
import editor from '@/views/fileStream/documentManage/editor'
import upload from '@/views/fileStream/documentManage/upload'
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'
import billTable from '@/views/fileStream/documentSend/components/billTable.vue'
import fastTable from '@/views/fileStream/documentSend/components/fastTable.vue'
import processMess from '@/views/fileStream/documentSend/components/process.vue'
import myform from './form.vue'
export default {
    components: { mytable, myform, upload, editor, processMess, billTable, fastTable },
    data() {
        return {
            fileIds: [],
            documentNodeList: [],
            recipientStatus: 0,
            approvalStatus: 0,
            templateName: '',
            content: '',
            documentType: 0,
            nooseStatus: 0,
            from: '',
            info: {},
            billInfo: {}, // 拟稿发文数据
            type: 1,
        }
    },
    created() {
        init_wx()
        this.get_info()
    },
    watch: {
        $route() {
            this.get_info()
        },
    },
    methods: {
        get_info() {
            const { id, from, type } = this.$route.query
            this.type = type
            this.from = from || ''
            if (id) {
                documentBillDetail({ id }).then((res) => {
                    if (res.code === 200 && res.data) {
                        const { data } = res
                        if (!data) return
                        this.info = data
                        this.billInfo = {
                            ...data,
                            jjcd: data.urgencyDegree,
                            miji: data.secrecyDegree,
                            userId: data.draftId,
                            xzfn: String(data.fileType),
                            fileIds: data.documentFileList,
                        }
                        const {
                            content,
                            documentFileList,
                            documentNodeList,
                            recipientStatus,
                            approvalStatus,
                            nooseStatus,
                            documentType,
                            templateName,
                        } = data
                        this.content = content
                        this.fileIds = documentFileList
                        this.documentNodeList = documentNodeList
                        this.approvalStatus = approvalStatus
                        this.recipientStatus = recipientStatus
                        this.templateName = templateName
                        this.documentType = documentType
                        this.nooseStatus = nooseStatus
                    }
                })
            }
        },
    },
}
</script>

<style lang="less" scoped>
.flex {
    display: flex;
    justify-content: space-between;
}
.process {
    padding: 20px 25px;
    padding-top: 94px;
}
.font {
    font-size: 16px;
}
.box {
    background-color: #ffffff;
    min-height: calc(100vh - 120px);
    width: 90%;
    margin: 0 auto;
    border-radius: 10px;
    // box-shadow: 2px 2px 4px #ccc;
}
.body {
    // width: 1280px;
    width: 100%;
    margin: auto !important;
}
.body-content {
    padding: 10px 44px 20px;
    box-sizing: border-box;
}
.left-com {
    padding: 20px 25px;
    background: #fff;
    width: 792px;
}
.info-title {
    // width: 1279px;
    height: 66px;
    background: #ffffff;

    font-size: 18px;
    color: #565656;
    margin-bottom: 20px;
    line-height: 66px;
    border-bottom: 1px solid rgb(235, 237, 240);
    button {
        margin: 0 10px;
        color: #c1c1c1;
    }
}
.bdr {
    border-radius: 4px;
}

.line {
    margin: 30px 0 15px;
    font-size: 18px;
    font-weight: bold;
    color: #3e3d3d;
}
.line:first-child {
    margin-top: 0;
}
.line::before {
    content: '';
    width: 4px;
    height: 20px;
    background: #3b9afa;
    display: inline-block;
    position: relative;
    top: 3px;
    margin-right: 10px;
}
</style>

1<template>
  <div>
    <div class="title flex flex-ai-c flex-jc-c font-w-b">
      {{ my_data.name }}
    </div>
    <table class="mytb">
      <!-- <tr class="body-c-title">
        <th class="title" colspan="4">{{ my_data.name }}</th>
      </tr> -->
      <tr class="thtt">
        <th colspan="1">办文号</th>
        <td colspan="3" class="text-a-l box-bb">{{ my_data.serialNumber }}</td>
      </tr>
      <tr class="thtt">
        <th colspan="1">来文日期</th>
        <td colspan="3" class="text-a-l box-bb">{{ my_data.lwsj ? moment(my_data.lwsj).format('YYYY-MM-DD') : '' }}</td>
      </tr>
      <tr class="thtt">
        <th colspan="1">紧急程度</th>
        <td colspan="1" class="text-a-l box-bb">{{ Filter(my_data.jjcd) }}</td>
        <th colspan="1">密级程度</th>
        <td colspan="1" class="text-a-l box-bb">{{ Filter(my_data.miji) }}</td>
      </tr>
      <tr class="thtt">
        <th colspan="1">来文单位</th>
        <td colspan="3" class="text-a-l box-bb">
          <template v-if="my_data.receiverCompany">
            {{ my_data.receiverCompany }}
          </template>
          <template v-if="my_data.draftCompany">
            <div v-for="(o, i) in my_data.draftCompany" :key="'draftCompany' + i" class="my-item">
              <a-tag class="tags flex0" color="blue">
                <OpenData :type="o.type" :openid="o.departmentId" :corpid="o.corpId"></OpenData>
              </a-tag>
            </div>
          </template>
        </td>
      </tr>
      <tr class="thtt">
        <th colspan="1">来文字号</th>
        <td colspan="3" class="text-a-l box-bb">{{ my_data.documentNumber }}</td>
      </tr>
      <tr class="thtt">
        <th colspan="1">经办人</th>
        <td colspan="1" class="text-a-l box-bb">
          <div v-for="(o, i) in my_data.draftId" :key="'jbr_list' + i" class="my-item">
            <a-tag class="tags flex0" color="blue">
              <OpenData :type="o.type" :openid="o.userId || o.id" :corpid="o.corpId"></OpenData>
            </a-tag>
          </div>
        </td>
        <th colspan="1">联系电话</th>
        <td colspan="1" class="text-a-l box-bb">{{ my_data.tellPhone }}</td>
      </tr>
      <tr class="thtt">
        <th colspan="1">文件标题</th>
        <td colspan="3" class="text-a-l box-bb">{{ my_data.wjbt }}</td>
      </tr>
      <tr class="thtt">
        <th colspan="1">正文</th>
        <td colspan="3" class="text-a-l box-bb">
          <div class="file_list" v-if="my_data.attach && my_data.attach.length">
            <div class="upload_file flex flex-ai-c flex-jc-fs" v-for="(o, i) in my_data.attach" :key="i">
              {{ o.fileName }}
              <fileDownAndPreView :file="o" />
            </div>
          </div>
          <div v-else v-html="my_data.content"></div>
        </td>
      </tr>
      <tr class="thtt">
        <th colspan="1">附件</th>
        <td colspan="3" class="text-a-l">
          <div class="file_list">
            <div class="upload_file flex flex-ai-c flex-jc-fs" v-for="(o, i) in my_data.fileIds" :key="i">
              {{ o.fileName }}
              <fileDownAndPreView :file="o" />
            </div>
          </div>
        </td>
      </tr>

      <tr class="bigtt align_l" v-if="my_data.documentType !== 3">
        <th>文件摘要</th>
        <td colspan="3" class="text-a-l" style="padding: 0">
          <!-- {{ my_data.summary }} -->
          <a-textarea readOnly v-model="my_data.summary" :auto-size="{ minRows: 5 }" />
        </td>
      </tr>

      <tr class="bigtt align_l">
        <th>拟办意见</th>
        <td colspan="3" class="text-a-l" style="padding: 0">
          <!-- {{ my_data.nbyj }} -->
          <a-textarea readOnly v-model="my_data.nbyj" :auto-size="{ minRows: 5 }" />
        </td>
      </tr>

      <nodes :list="formatList(list)" />
    </table>
  </div>
</template>

<script>
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { sysFileInfoDownload } from '@/api/modular/system/fileManage'
import nodes from '@/views/fileStream/documentApproval/documentManage/nodes.vue'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import fileDownAndPreView from '@/views/fileStream/documentSend/components/fileDownAndPreView.vue'
import moment from 'moment'
export default {
  data() {
    return {
      moment,
      flow_secrecy_degree: [],
      resList: [],
      jbr_list: [],
    }
  },
  props: {
    info: {
      type: Object,
      default() {
        return {}
      },
    },
    from: {
      type: String,
      default: '',
    },
  },
  computed: {
    my_data() {
      return this.init_data().my_data
    },
    list() {
      let { list } = this.init_data()
      list = list.filter((node) => node.sort !== -1)
      return list
    },
  },
  created() {
    sysDictTypeDropDown({ code: 'flow_secrecy_degree' }).then((res) => {
      this.flow_secrecy_degree = res.data
    })
  },
  components: {
    OpenData,
    fileDownAndPreView,
    nodes,
  },
  methods: {
    Filter(s) {
      const values = this.flow_secrecy_degree.filter((item) => item.code == s)
      if (values.length > 0) {
        return values[0].value
      }
    },
    formatListMess(node) {
      const arr = []
      if (node.deptInfos && node.deptInfos.length > 0) {
        node.deptInfos.forEach((dep) => {
          dep.deptUsers.forEach((k) => {
            arr.push(k)
          })
        })
      }
      node.nodes = [...node.nodes, ...arr].filter((j) => j.userId && j.approvalFlag !== 2)
    },
    formatList(list) {
      const listCopy = JSON.parse(JSON.stringify(list))
      listCopy.forEach((j) => {
        this.formatListMess(j)
      })
      return listCopy.filter((j) => j.sort != -1 && ![99, 100].includes(j.sort))
    },
    formatTdtt(swsj) {
      if (!swsj) return ''
      return moment(swsj).format('YYYY-MM-DD')
    },
    onChange(date, dateString) {
      console.log(date, dateString)
    },
    formatApprovalTime(approvalTime) {
      if (!approvalTime) return ''
      approvalTime = approvalTime.replace(/-/g, '/')
      const date = new Date(approvalTime)
      const dateStr = date.toLocaleString()
      return dateStr.replace(/\//g, '-')
    },
    formatDraftId(id) {
      if (!id) return []
      return JSON.parse(id).map((j) => ({ ...j, id: j.userId || j.departmentId }))
    },
    // 下载当前文件
    download(record) {
      this.cardLoading = true
      sysFileInfoDownload({ id: record.fileId })
        .then((res) => {
          this.cardLoading = false
          this.editAcceptBill({ downloadStatus: 1 })
          this.downloadfile(res)
          // eslint-disable-next-line handle-callback-err
        })
        .catch(() => {
          this.cardLoading = false
          this.$message.error('下载错误：获取文件流错误')
        })
    },
    init_data() {
      const { info } = this
      let my_data = {}
      let list = []
      if (!info.name) {
        return { my_data, list }
      }
      const {
        acceptTime,
        name,
        urgencyDegree,
        secrecyDegree,
        deviseOpinion,
        draftId,
        nodeParam,
        // approvalWay,
        tellPhone,
        summary,
        attach, //正文
        fileIds, //附件
        title,
        documentNodeList,
        documentFileList,
        documentNumber,
        receiverCompany,
        draftCompany,
        mainFile,
        templateId,
        documentType,
        content,
        serialNumber
      } = info
      const form = {
        lwsj: acceptTime,
        name,
        jjcd: urgencyDegree ? String(urgencyDegree) : '',
        miji: secrecyDegree ? String(secrecyDegree) : '',
        nbyj: deviseOpinion,
        receiverCompany,
        draftCompany: this.formatDraftId(draftCompany),
        draftId: this.formatDraftId(draftId),
        // spfs: '1' || String(approvalWay),
        wjbt: title,
        list: nodeParam,
        nodes: documentNodeList,
        tellPhone,
        attach: mainFile && [mainFile], //正文
        fileIds: documentFileList, //附件
        summary,
        documentNumber,
        documentType,
        templateId,
        serialNumber,
        content,
      }
      this.jbr_list = this.formatDraftId(draftId)
      //
      my_data = form
      //
      list = documentNodeList.slice(0, documentNodeList.length - 1)
      this.resList = documentNodeList.find((j) => j.sort === 100)
      return { my_data, list }
    },
  },
}
</script>

<style lang="less" scoped>
.flex {
  display: flex;
  justify-content: space-between;
}
.font {
  font-size: 16px;
}

.file_list {
  // flex-wrap: wrap;
  .upload_file {
    // cursor: pointer;
    // margin-right: 8px;
  }
}

.mytb {
  width: 100%;
  border: 1px solid black;
  font-size: 14px;
  table-layout: fixed;
  th,
  td {
    text-align: center;
    border: 1px solid black;
  }
  .thtt {
    height: 50px;
    td {
      padding: 0 10px;
    }
  }
  .tdtt {
    height: 50px;
  }
  .bigtt {
    height: 150px;
  }
  .align_l {
    td {
      text-align: left;
      padding: 15px;
      vertical-align: text-top;
      white-space: pre-line;
    }
  }
  ::v-deep .ant-input {
    border: none;
    box-shadow: none;
    &:focus {
      box-shadow: none;
    }
  }
}
.title {
  color: red;
  font-size: 26px;
  height: 100px;
}
</style>
